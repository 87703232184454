<template>
  <v-form @submit.prevent="handleSubmit">
    <ValidationObserver ref="user_form" v-slot="{ invalid }">
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer />
          <v-btn icon @click="handleClose()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="readonly">{{
          $capitalize($tc("model.unities_read_only"))
        }}</v-card-subtitle>
        <v-card-text>
          <ValidationProvider name="user" rules="required" v-slot="{ errors }">
            <v-text-field
              v-if="form.id"
              :label="$capitalize($tc('model.unities_user'))"
              :value="form.user_name"
              readonly
              :hint="$capitalize($tc('model.unities_message_user'))"
              persistent-hint
            ></v-text-field>
            <v-autocomplete
              v-else
              :disabled="loading || loading_user"
              name="user"
              :error-messages="errors"
              :label="$capitalize($tc('model.unities_user'))"
              :value="form.user"
              @input="update('user', $event.id)"
              :items="users"
              item-text="name"
              item-value="id"
              :loading="loading_user"
              return-object
            >
              <template v-slot:append-outer>
                <v-btn @click="getUsers()" :disabled="loading_user" icon>
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </ValidationProvider>

          <ValidationProvider name="team_id" rules="" v-slot="{ errors }">
            <v-select
              :label="
                __('Equipe', 'unities', 1)
              "
              item-text="name"
              item-value="id"
              name="team_id"
              :error-messages="errors"
              :items="teams"
              :disabled="loading"
              v-model="form.team_id"
              :clearable="!readonly"
              :readonly="readonly"
            ></v-select>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions v-if="!readonly">
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose">{{
            __('Cancelar', 'unities')
          }}</v-btn>
          <v-btn :loading="loading" type="submit" color="primary">{{
            __('Salvar', 'unities')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-form>
</template>

<script>
import {
  store as storeUnity,
  update as updateUnity,
} from "../../services/unities";
import { index as indexUsers } from "../../services/users";
import i18n from '@/mixins/i18n';
import { index as indexTeams } from "@/services/teams";

export default {
  name: "unity-form",
  props: ["title", "value", "readonly"],
  mixins: [i18n],
  data: () => ({
    loading: false,
    users: [],
    loading_user: false,
    loading_service_types: false,
    service_types: [],
    teams: [],
  }),
  methods: {
    async getTeams() {
      const response = await indexTeams();
      this.teams = response.data;
    },
    update(key, value) {
      this.$emit("input", { ...this.form, [key]: value });
    },
    async handleSubmit() {
      const validation = await this.$refs.user_form.validate();

      if (validation) {
        this.loading = true;
        try {
          if (this.form.id) {
            await updateUnity(
              {
                ...this.form,
                team_id: this.form.team_id || null,
              },
              this.form.id
            );
          } else {
            await storeUnity({
              user_id: this.form.user,
              service_types: this.form.service_types,
              team_id: this.form.team_id || null,
            });
          }

          this.$emit("submit", {
            ...this.form,
            team_id: this.form.team_id || null,
          });
          this.getUsers();
          this.handleClose();
        } catch (error) {
          this.$store.commit("sendMessage", {
            text: "Falha ao cadastrar unidade",
            color: "red",
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async getUsers() {
      this.loading_user = true;
      this.users = [];
      try {
        this.users = await indexUsers({
          list: true,
          not_unity: true,
        });
      } catch (error) {
        this.$store.commmit("sendMessage", {
          text: "Falha ao carregar utilizadores",
          color: "red",
        });
      } finally {
        this.loading_user = false;
      }
    },
    async getServiceTypes() {
      this.loading_service_types = true;
      this.service_types = [];

      try {
        const response = await this.$http.get("/lead_types");
        this.service_types = response.data;
      } catch (error) {
        this.$store.commmit("sendMessage", {
          text: "Falha ao carregar utilizadores",
          color: "red",
        });
      } finally {
        this.loading_service_types = false;
      }
    },
    handleClose() {
      this.$emit("input", {});
      this.$refs.user_form.reset();
      this.$emit("close");
    },
  },
  computed: {
    form: function () {
      return this.value ? this.value : {};
    },
  },
  created() {
    this.getUsers();
    this.getServiceTypes();
    this.getTeams();
  },
};
</script>