<template>
  <div>
    <div v-if="buttonRating">
      <v-list-item
        v-if="unity.admin_rating == 0"
        @click="setRating(unity, 1)"
      >
        <v-icon left size="20">mdi-check-decagram</v-icon>
        <v-list-item-title>{{
          __('Iluminar', 'unities')
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="unity.admin_rating == 0"
        @click="setRating(unity, -1)"
      >
        <v-icon left size="20">mdi-alert-decagram</v-icon>
        <v-list-item-title>{{
          __('Eliminar', 'unities')
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-else-if="unity.admin_rating !== 0"
        @click="setRating(unity, 0)"
      >
        <v-icon left size="20">mdi-eraser</v-icon>
        <v-list-item-title>{{
          __('Remover avaliação', 'unities')
        }}</v-list-item-title>
      </v-list-item>
    </div>

    <div v-if="statusRating">
      <v-tooltip top v-if="unity.admin_rating == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="blue" v-bind="attrs" v-on="on" small
            >mdi-check-decagram</v-icon
          >
        </template>
        <span>{{
          __('Bom desempenho', 'unities')
        }}</span>
      </v-tooltip>
      <v-tooltip top v-else-if="unity.admin_rating == -1">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="red lighten-1" v-bind="attrs" v-on="on" small
            >mdi-alert-decagram</v-icon
          >
        </template>
        <span>{{
          __('Desempenho ruim', 'unities')
        }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import {
  update as updateUnity
} from "@/services/unities";
export default {
  props: {
    unity: {
      type: Object,
      default: null
    },
    buttonRating: {
      type: Boolean,
      default: false
    },
    statusRating: {
      type: Boolean,
      default: false
    }
  },
  mixins: [i18n],
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async setRating(unity, rating) {
      unity.admin_rating = rating;
      try {
        await updateUnity(
          {
            admin_rating: rating,
          },
          unity.id
        );
      } catch (error) {
        this.items.map((item) => {
          if (item.id === unity.id) {
            item.admin_rating = unity.admin_rating;
          }

          return unity;
        });
      }
    },
  },
};
</script>
